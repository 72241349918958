
function LinkButton(props) {
    return (
        <div className="mt-11">
            <a  className="py-4 px-5 rounded-md shadow-md"
                style={{background: '#826F54'}}
                href={props.href}>{props.name}</a>
        </div>
    )
}

export default LinkButton