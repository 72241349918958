
function Social(props) {
    return (
        <div className={props.className}>
            <a href={props.href} rel="noreferrer" target="_blank">
                <img width={30} height={30} src={props.icon} alt={props.name} />
            </a>
        </div>
    )
}

export default Social