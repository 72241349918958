import Home from './pages/Home'

function App() {
  return (
    <div className="bg-[url('./assets/images/lipotimarster-1.webp')] bg-cover bg-center">
      <div className="bg-black bg-opacity-50">
        <Home />
      </div>
    </div>
  );
}

export default App;
