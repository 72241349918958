import LinkButton from '../components/LinkButton'
import Social from '../components/Social'
import Facebook from '../assets/icons/facebook.png'
import Instagram from '../assets/icons/instagram.png'

function Home() {
    return (
        <div className="w-screen h-screen flex flex-col justify-start align-middle text-center text-white md:justify-center md:mt-0">
            <header className="mt-16">
                <h1 className="text-5xl shadow-lg font-bold">
                    Lipóti Pékség
                </h1>
                <div className="flex justify-center text-2xl">
                <span className="mr-1">-</span>
                <h2 className=' shadow-xl'>Szeged Mars tér 1-3</h2>
                <span className="ml-1">-</span>
                </div>
            </header>
            <main className="mt-16 text-lg flex-col">
                <ul>
                <li>H-P: 6-19 óráig</li>
                <li>Szo: 6-12 óráig</li>
                <li className="text-red-500">V: Zárva</li>
                </ul>
                <div className="mt-10 w-full flex flex-col justify-center text-base">
                <h3 className="text-2xl">Elérhetőségek</h3>
                <div className="mt-2 flex flex-col justify-center text-lg">
                    <a className='mb-1' href="tel:+36204041332">+36 20 404 13 32</a>
                    <a href="mailto:lipotimarster@gmail.com">lipotimarster@gmail.com</a>
                </div>
                </div>
                <LinkButton href="mailto:lipotimarster@gmail.com" name="Előrendelés" />
            </main>
            <footer className="mt-16 flex flex-col justify-center align-middle">
                <div className="flex justify-center align-middle">
                    <Social className="mr-3" icon={Facebook} name="Facebook" href="https://www.facebook.com/lipotimarster" />
                    <Social icon={Instagram} name="Instagram" href="https://instagram.com/lipotimarster" />
                </div>
                <p className="mt-3 text-sm opacity-80">© lipotimarster.hu - 2023</p>
            </footer>
        </div>
    )
}

export default Home